<template>
	<div @click.self="shopShow = false" style="overflow-x: hidden" v-loading.fullscreen.lock="fullscreenLoading">
		<Navigation />
		<!-- 分类区 -->
		<div class="bg_box">
			<div class="ct_box">
				<Nav title="2" />
				<!-- 门户类型 -->
				<div class="type2 flex">
					<p>所属平台：</p>
					<ul class="flex ul2">
						<li :class="PortalType.title == '不限' ? 'active' : ''" @click="PortalShow('不限', 0)">
							不限
						</li>
						<li :class="PortalType.title == item ? 'active' : ''" v-for="(item, index) in PortalList"
							:key="index" @click="PortalShow(item, index)">
							<!-- PortalType = item -->
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>行业类型：</p>
					<ul class="flex ul2">
						<li :class="ChannelType.title == '不限' ? 'active' : ''" @click="ChannelShow('不限', 0)">
							不限
						</li>
						<li :class="ChannelType.title == item ? 'active' : ''" v-for="(item, index) in ChannelList"
							:key="index" @click="ChannelShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>参考粉丝数：</p>
					<ul class="flex ul2">
						<li :class="TerritoryType.title == '不限' ? 'active' : ''" @click="TerritoryShow('不限', 0)">
							不限
						</li>
						<li :class="TerritoryType.title == item ? 'active' : ''" v-for="(item, index) in TerritoryList"
							:key="index" @click="TerritoryShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>平均阅读量：</p>
					<ul class="flex ul2">
						<li :class="ViewsType.title == '不限' ? 'active' : ''" @click="ViewsShow('不限', 0)">
							不限
						</li>
						<li :class="ViewsType.title == item ? 'active' : ''" v-for="(item, index) in ViewsList"
							:key="index" @click="ViewsShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>是否官方：</p>
					<ul class="flex ul2">
						<li @click="isOfficialShow('不限')" :class="officialType == '不限' ? 'active' : ''">
							不限
						</li>
						<li v-for="(item, index) in officialList" :key="index"
							:class="officialType == item ? 'active' : ''" @click="isOfficialShow(item)">{{ item }}</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>V认证：</p>
					<ul class="flex ul2">
						<li @click="isVAuthShow('不限')" :class="isVAuthType == '不限' ? 'active' : ''">
							不限
						</li>
						<li v-for="(item, index) in isVAuthList" :key="index"
							:class="isVAuthType == item ? 'active' : ''" @click="isVAuthShow(item)">{{ item }}</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>价格区间：</p>
					<ul class="flex ul2">
						<li :class="MoneyType.title == '不限' ? 'active' : ''" @click="MoneyShow('不限', 0)">
							不限
						</li>
						<li :class="MoneyType.title == item ? 'active' : ''" v-for="(item, index) in MoneyList"
							:key="index" @click="MoneyShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>特殊类目：</p>
					<ul class="flex ul2">
						<li :class="SpecialType == '不限' ? 'active' : ''" @click="SpecialShow('不限')">不限</li>
						<!-- <li v-show="SpecialList.is_pub_contact" :class="SpecialType == '可发联系方式' ? 'active' : ''"
							@click="SpecialShow('可发联系方式')">
							{{ SpecialList.is_pub_contact }}
						</li> -->

						<!-- <li v-show="SpecialList.is_pub_image" :class="SpecialType == '焦点图' ? 'active' : ''"
							@click="SpecialShow('焦点图')">
							{{ SpecialList.is_pub_image }}
						</li>
						<li v-show="SpecialList.is_pub_url" :class="SpecialType == '文字链' ? 'active' : ''"
							@click="SpecialShow('文字链')">
							{{ SpecialList.is_pub_url }}
						</li> -->
						<li v-show="SpecialList.is_pub_weekend" :class="SpecialType == '周末可发' ? 'active' : ''"
							@click="SpecialShow('周末可发')">
							{{ SpecialList.is_pub_weekend }}
						</li>
						<!-- 新加特殊类目项:是否可加配图 是否可发视频 -->
						<li v-show="SpecialList.is_pub_video" :class="SpecialType == '可发视频' ? 'active' : ''"
							@click="SpecialShow('可发视频')">
							{{ SpecialList.is_pub_video }}
						</li>
					</ul>
				</div>

				<div class="type3 flex">
					<p>排序：</p>
					<ul class="flex ul2">
						<!-- 状态码：0:未选中 1:升序 2:降序-->
						<li @click="StateShow('默认')" :class="SortType == '默认' ? 'active' : ''">
							默认
						</li>
						<li v-if="SortList.member_price" @click="StateShow('价格')" :id="SortType != '默认' && SortType == '价格' && MoneyState == 1
		? 'activeTrue'
		: SortType != '默认' && SortType == '价格' && MoneyState == 2
			? 'activeFalse'
			: ''
		" :class="SortType == '价格' ? 'active' : ''">
							价格
						</li>
						<li v-if="SortList.weight" @click="StateShow('电脑权重')" :id="SortType != '默认' &&
		SortType == '电脑权重' &&
		CopmutedState == 1
		? 'activeTrue'
		: SortType != '默认' &&
			SortType == '电脑权重' &&
			CopmutedState == 2
			? 'activeFalse'
			: ''
		" :class="SortType == '电脑权重' ? 'active' : ''">
							权重
						</li>
					</ul>
				</div>
				<div class="type4 flex">
					<p class="type_title_n">媒介名称：</p>
					<el-input @keyup.enter.native="page = 1; ListFn()" v-model="mediaName"
						placeholder="请输入编号或名称"></el-input>
					<div class="flex link_box">
						<p>可发内链：</p>
						<el-select v-model="link" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<div class="flex link_box">
						<p>可发联系方式：</p>
						<el-select v-model="relation" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<button class="sf_but" type="primary" @click="page = 1; ListFn()">筛选</button>
					<button class="sf_but2" type="primary" @click="page = 1; AllMediaTab()">
						重置筛选
					</button>
					<collect :is_collect="is_collect" @collectShow="collectShow" />
					<mediaWhiteList />
				</div>
			</div>
		</div>
		<!-- 自媒体资源  -->
		<table class="table_3">
			<thead>
				<td>
					<input type="checkbox" v-model="Allcheck" @click="checkSwitch()" label=""
						v-show="tableData.length != 0" />
				</td>
				<td>媒介名称</td>
				<td>平台</td>
				<td>价格</td>
				<td>行业类型</td>
				<td>可发内链</td>
				<td>可发联系方式</td>
				<td>粉丝数</td>
				<td>平均浏览量</td>
				<td>V认证</td>
				<!-- <td>截稿时间</td>
				<td>出稿速度</td> -->
				<td>备注(仅供参考)</td>
				<td>收藏</td>
			</thead>
			<tbody>
				<tr v-for="(item, index) in tableData" :key="index">
					<td>
						<input type="checkbox" v-model="item.check" @click="onPitch(index)" label="" />
					</td>
					<td>
						<td_remark :text="item.title" />
					</td>
					<td>{{ item.portal_title }}</td>
					<td>
						￥{{ item.price.toFixed(2) }}
					</td>
					<td>{{ item.industry_classify_title }}</td>
					<td :style="{
		color: item.is_pub_link == 1 ? '#55C964' : '#FF0000',
	}">
						{{ item.is_pub_link == 0 ? '否' : '是' }}
					</td>
					<td :style="{
		color: item.is_pub_contact == 1 ? '#55C964' : '#FF0000',
	}">
						{{ item.is_pub_contact == 0 ? '否' : '是' }}
					</td>
					<td>
						{{ item.fans_num }}
					</td>
					<td>
						{{ item.views }}
					</td>
					<td :style="{
		color: item.is_v_auth == 1 ? '#55C964' : '#FF0000',
	}">
						{{ item.is_v_auth == 0 ? '否' : '是' }}
					</td>
					<!-- <td>{{ item.stop_draft_time == 0 ? '无' : item.stop_draft_time + ':00' }}</td>
					<td>
						{{
		item.draft_speed == 0
			? '不限'
			: item.draft_speed == 1
				? '1小时'
				: item.draft_speed == 2
					? '2小时'
					: item.draft_speed == 3
						? '3小时'
						: item.draft_speed == 4
							? '4小时'
							: item.draft_speed == 5
								? '当天'
								: '次日'
	}}
					</td> -->
					<td>
						<td_remark :text="item.remark" />
					</td>
					<td>
						<div class="collect_box flex">
							<p @click.stop="jumpLink(item)">查看案例</p>
							<!-- v-if="item.collect == 0"  -->
							<p v-if="item.is_collect == 0" class="collect" @click.stop="collectGet(item)">收藏</p>
							<!-- v-if="item.collect == 1" -->
							<p v-if="item.is_collect == 1" class="collect2" @click.stop="collectClose(item)">已收藏
							</p>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<!-- 分页器 -->
		<div class="paging_box">
			<pagination :page="page" :count="count" @pageChange="pageChange" :total_page="total_page" />
		</div>
		<!-- 购物车 -->
		<div v-show="shopShow == false" class="shopping_car">
			<div class="car" @click.stop="shopShow = true">
				<p>{{ CarList.length }}</p>
			</div>
		</div>
		<div :class="shopShow ? 'shop_box' : 'shop_none_box'">
			<div class="title flex" style="justify-content: space-between; align-items: center">
				<h3 class="carTitle">已选媒介: {{ CarList.length }}</h3>
				<el-button v-show="CarList.length != 0" type="primary" @click="empty()">清空已选媒介</el-button>
				<span class="close" @click="shopShow = false"></span>
			</div>
			<ul class="listbox" v-show="CarList.length != 0">
				<li v-for="(item, index) in CarList" :key="index">
					<!-- <img :src="item.logo" alt="" /> -->
					<div>
						<td_remark :text="item.title" />
					</div>
					<p class="price">￥{{ item.price.toFixed(2) }}</p>

					<span class="deleteIcon" @click="detale(item)"></span>
				</li>
			</ul>
			<el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
			<div class="bottom_box">
				<div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
					<p style="font-size: 1.4rem; color: #999">合计：</p>
					<p class="totalMoney">
						￥<span>{{ totalMoney ? totalMoney : '0.00' }}</span>
					</p>
				</div>
				<button class="put_but" @click="putFn">立即投放</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Nav from '@/components/MediaNav.vue'
import { checkAll } from '@/util/checkAll'
export default {
	name: '',
	computed: {
		...mapState(['Media', 'MediaNav', 'TypeItem', 'ActiveChild']),
		totalMoney: function () {
			var n = 0;
			this.CarList.forEach((item, index) => {
				if (this.CarList[index].check) {
					n += this.CarList[index].price
				}
			})
			return n.toFixed(2)
		}
	},
	components: {
		Nav,
	},
	watch: {
		tableData: {
			handler(newValue) {
				if (this.tableData.length > 0) {
					let count = 0
					for (var i = 0; i < this.tableData.length; i++) {
						if (this.tableData[i].check == true) {
							count++;
						}
					}
					if (count == this.tableData.length) {
						this.Allcheck = true
					} else {
						this.Allcheck = false
					}
				}
			},
			deep: true,
		}

	},
	created() { },
	mounted() {
		this.fullscreenLoading = true
		setTimeout(() => {
			for (const i in this.$store.state.Media) {
				if (this.$store.state.Media[i].id == 2) {
					this.PortalList = this.$store.state.Media[i].portal_list // 所属平台
					this.ChannelList = this.$store.state.Media[i].industry_list
					this.TerritoryList = this.$store.state.Media[i].fans_list
					this.ViewsList = this.$store.state.Media[i].views_list
					this.RecordingList = this.$store.state.Media[i].inlet_category
					this.MoneyList = this.$store.state.Media[i].price_range
					this.SpecialList = this.$store.state.Media[i].special_list
					this.officialList = this.$store.state.Media[i].official_list
					this.isVAuthList = this.$store.state.Media[i].is_v_auth_list
					this.SortList = this.$store.state.Media[i].order_list
				}
			}
			this.user_info = this.$util.getLocalStorage('user_info')
			this.ListFn()
			this.fullscreenLoading = false
		}, 200)
	},
	data() {
		return {
			user_info: {},
			// 门户类型
			PortalType: {
				id: 0,
				title: '不限',
			},
			PortalList: [],
			// 频道类型
			ChannelType: {
				id: 0,
				title: '不限',
			},
			ChannelList: [],
			//所属区域
			TerritoryType: {
				id: 0,
				title: '不限',
			},
			TerritoryList: [],
			// 平均阅读量
			ViewsType: {
				id: 0,
				title: '不限',
			},
			ViewsList: [],
			// 收录效果
			RecordingType: {
				id: 0,
				title: '不限',
			},
			RecordingList: [],
			// 价格区间
			MoneyType: {
				id: 0,
				title: '不限',
			},
			MoneyList: [],
			// 特殊类目
			SpecialType: '不限',
			SpecialList: [],
			// 是否官方筛选项
			officialType: '不限',
			officialList: [],
			// 是否V认证
			isVAuthType: '不限',
			isVAuthList: [],
			// 排序
			SortType: '默认',
			SortList: [],
			MoneyState: 0, //价钱升序降序控制器
			CopmutedState: 0, //电脑权重升序降序控制器
			MoveState: 0, //移动权重升序降序控制器
			/*-----------------媒介筛选------------------------*/
			mediaName: '', //媒介名称,
			linkList: ['是', '否'], //可发链接列表
			link: '', //可发链接
			relation: '', //可发联系方式
			/*数据表格*/
			tableData: [],
			CarList: [],
			// 购物车任务栏切换
			shopShow: false,
			Allcheck: false,
			ids: [],
			fullscreenLoading: false,
			count: 0,//总条数
			total_page: 0, //分页总页数
			page: 1,
			title: '自媒体',
			is_collect: 0,
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.ListFn()
		},
		StateShow(item) {
			/*
			每次点击时需要重置价格-电脑权重-移动权重的状态,在每个判断条件中需做重置
			*/
			this.SortType = item
			switch (item) {
				case '默认':
					this.MoneyState = 0
					this.CopmutedState = 0
					this.MoveState = 0
					break

				case '价格':
					this.CopmutedState = 0
					this.MoveState = 0
					if (this.MoneyState == 0) {
						this.MoneyState = 1
					} else if (this.MoneyState == 1) {
						this.MoneyState = 2
					} else {
						this.MoneyState = 1
					}
					break
				case '电脑权重':
					this.MoneyState = 0
					this.MoveState = 0
					if (this.CopmutedState == 0) {
						this.CopmutedState = 1
					} else if (this.CopmutedState == 1) {
						this.CopmutedState = 2
					} else {
						this.CopmutedState = 1
					}
					break
				case '移动权重':
					this.MoneyState = 0
					this.CopmutedState = 0
					if (this.MoveState == 0) {
						this.MoveState = 1
					} else if (this.MoveState == 1) {
						this.MoveState = 2
					} else {
						this.MoveState = 1
					}
					break
				default:
					break
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		onPitch(index) {
			// 飞入购物车动画
			this.tableData[index].check = !this.tableData[index].check
			if (this.tableData[index].check) {
				const x = event.clientX - 20
				const y = event.clientY - 20
				this.createBall(x, y)
				this.shopShow = true
				// this.CarList.push(this.tableData[index])
				this.CarList.push(this.tableData[index])
				/*------------------------------------------------------ */
			} else {
				this.tableData.forEach((item, index) => {
					this.CarList.forEach((el, i) => {
						if (!this.tableData[index].check && this.CarList[i].id == this.tableData[index].id) {
							this.CarList.splice(i, 1)

						}
					})
				})
			}
		},
		detale(element) {
			this.CarList.forEach((el, i) => {
				if (el.id == element.id) {
					this.CarList.splice(i, 1)
				}
			})
			// this.CarList.splice(index,1)
			this.tableData.forEach((item, index) => {
				if (item.id == element.id) {
					this.tableData[index].check = false
				}
			})
		},
		// 清空购物车
		empty() {
			this.$confirm('是否清空已选媒介?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.fullscreenLoading = true
					// setTimeout(() => {
					this.CarList = []
					this.shopShow = false
					// this.toggleSelection()
					this.tableData.forEach((item, index) => {
						if (item.check) {
							return (item.check = false)
						}
					})
					this.fullscreenLoading = false
					// }, 500)
					this.$message({
						type: 'success',
						message: '已清空已选媒介',
					})
				})
				.catch(() => {
					this.$message({
						type: 'warning',
						message: '已删除',
					})
				})
		},
		createBall(left, top) {
			const bar = document.createElement('ball')
			bar.style.position = 'fixed'
			bar.style.left = left + 'px'
			bar.style.top = top + 'px'
			bar.style.width = '20px'
			bar.style.height = '20px'
			bar.style.borderRadius = '50%'
			bar.style.backgroundColor = 'red'
			bar.style.transition =
				'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
			document.body.appendChild(bar)
			setTimeout(() => {
				const x = document.body.clientWidth * 1.5
				const y = document.body.clientHeight * 1 - 500
				bar.style.top = y + 'px'
				bar.style.left = x + 'px'
			}, 0)
			bar.ontransitionend = function () {
				this.remove()
			}
		},
		// 全选 反选
		checkSwitch() {
            this.Allcheck = !this.Allcheck
            this.CarList = checkAll(this.Allcheck,this.tableData,this.CarList)
            if(this.Allcheck){
                this.shopShow = true
            }
        },
		// 门户类型切换
		PortalShow(item, index) {
			this.PortalType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 频道类型切换
		ChannelShow(item, index) {
			this.ChannelType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 所属区域类型切换
		TerritoryShow(item, index) {
			this.TerritoryType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 平均阅读量
		ViewsShow(item, index) {
			this.ViewsType = {
				title: item,
				id: index ? parseInt(index) : 0
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 收录效果
		RecordingShow(item, index) {
			this.RecordingType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 价格区间切换
		MoneyShow(item, index) {
			this.MoneyType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},

		// 特殊类目切换
		SpecialShow(item) {
			this.SpecialType = item
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 切换页
		currentTab(val) {
			this.page = val
			this.ListFn()
			this.TabCheck()
		},
		// 上一页
		prevClick() {
			this.page -= 1
			this.ListFn()
			this.TabCheck()
		},
		// 下一页
		// next-click
		nextClick() {
			this.page += 1
			this.ListFn()
			this.TabCheck()
		},
		// 是否切换收藏列表
		collectShow(id) {
			this.is_collect = id
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 获取列表数据
		ListFn() {
			let order = null
			let data = {}
			if (this.SortType == '价格') {
				data.sort = 'member_price'
				if (this.MoneyState == 1) {
					data.order = 'asc'
				} else {
					data.order = 'desc'
				}
			} else if (this.SortType == '权重') {
				data.sort = 'weight'
				if (this.CopmutedState == 1) {
					data.order = 'asc'
				} else {
					data.order = 'desc'
				}
			}

			data.page = this.page
			data.limit = 20
			data.category_id = 2
			if (this.PortalType.id != 0) {
				data.portal_id = this.PortalType.id
			}
			if (this.ChannelType.id != 0) {
				data.industry_id = this.ChannelType.id
			}
			if (this.ViewsType.id != 0) {
				data.views = this.ViewsType.id
			}
			if (this.MoneyType.id != 0) {
				data.price_id = this.MoneyType.id
			}
			if (this.RecordingType.id != 0) {
				data.inlet_category = this.RecordingType.id
			}
			if (this.SpecialType == '周末可发') {
				data.is_pub_weekend = 1
			} else if (this.SpecialType == '可发视频') {
				data.is_pub_video = 1
			}
			if (this.TerritoryType.id != 0) {
				data.fans_num = this.TerritoryType.id
			}
			if (this.officialType == '非官方媒介') {
				data.is_official = 0
			} else if (this.officialType == '官方媒介') {
				data.is_official = 1
			}
			if (this.isVAuthType == '未认证') {
				data.is_v_auth = 0
			} else if (this.isVAuthType == 'V认证') {
				data.is_v_auth = 1
			}
			if (this.is_collect) {
				data.collect = this.is_collect
			}
			if (this.mediaName !== '') {
				data.title = this.mediaName
			}
			if (this.link != '' && this.link == '是') {
				data.is_pub_link = 1
			} else if (this.link !== '' && this.link == '否') {
				data.is_pub_link = 0
			}
			if (this.relation !== '' && this.relation == '是') {
				data.is_pub_contact = 1
			} else if (this.relation !== '' && this.relation == '否') {
				data.is_pub_contact = 0
			}

			this.fullscreenLoading = true
			this.curlGet('/api/medium/list', data).then((res) => {
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData.forEach((item, index) => {
						this.$set(this.tableData[index], 'check', false)
						if (this.CarList.length != 0) {
							this.CarList.forEach((el, i) => {
								if (this.tableData[index].id == this.CarList[i].id) {
									this.tableData[index].check = true
								}
							})
						}
					})
					this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
				}
				this.fullscreenLoading = false
			})
		},
		// 重置选择
		AllMediaTab() {
			this.mediaName = ''
			this.PortalType = {
				id: 0,
				title: '不限',
			}
			this.ChannelType = {
				id: 0,
				title: '不限',
			}
			this.TerritoryType = {
				id: 0,
				title: '不限',
			}
			this.RecordingType = {
				id: 0,
				title: '不限',
			}
			this.ViewsType = {
				id: 0,
				title: '不限',
			}
			this.MoneyType = {
				id: 0,
				title: '不限',
			}
			this.officialType = '不限'
			this.link = ''
			this.relation = ''
			this.SpecialType = '不限'
			// 是否V认证
			this.isVAuthType = '不限'
			this.SortType = '默认'
			this.mediaName = ''
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 是否为官方媒介
		isOfficialShow(item) {
			this.officialType = item
			this.ListFn()
		},
		// V认证
		isVAuthShow(item) {
			this.isVAuthType = item
			this.ListFn()
		},
		// 判断切换数据时是否选中该条数据
		TabCheck() {
			this.fullscreenLoading = true
			setTimeout(() => {
				this.tableData.forEach((item, index) => {
					this.CarList.forEach((e, i) => {
						if (e.id == item.id && !item.check) {
							return (item.check = true)
						} else {
							return (item.check = false)
						}
					})
				})
				this.fullscreenLoading = false
			}, 500)
		},
		// 立即投放
		putFn() {
			if (this.CarList.length == 0) {
				return this.$message({
					type: 'warning',
					message: '请选择套餐',
				})
			} else if (!this.$user_info) {
				this.$message({
					type: 'warning',
					message: '请登录',
				})
			} else {
				this.$store.commit('shopPush', this.CarList)
				if (this.$store.state.fillActive == false) {

					this.$router.push('/user/SetMenu/ManuscriptPreview')
				} else {
					this.$store.commit('childTab', '自媒体发布')
					this.$router.push({
						path: '/user/SetMenu/fill?id=2',
					})
				}
			}
		},
		// 收藏
		collectGet(item) {
			if (!this.user_info) {
				this.$message({
					type: 'warning',
					message: '请登录',
					offset: 90,
				})
			} else {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					if (res.data.code) {
						this.$message({
							message: '收藏成功',
							type: 'success'
						})
						this.ListFn()
					}
				})
			}
		},
		// 取消收藏
		collectClose(item) {
			this.$confirm('是否取消收藏?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					if (res.data.code) {
						this.$message({
							type: 'success',
							message: '取消收藏成功!'
						});
						this.ListFn()
					}
				})

			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},

	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/Media.scss';
@import '../../scss/overText.scss'
</style>